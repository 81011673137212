<template>
  <v-card class="mt-2 ml-2 mr-2">


    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabCompanyClick">
        COMPANY
      </v-tab>
      <v-tab class="justify-start" @click="tabDivisionClick">
        BRANCH
      </v-tab>
      <v-tab class="justify-start" @click="tabSalesmanClick">
        PEGAWAI
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Application Setting
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FCompanyTable ref="refCompanyTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FDivisionTable ref="refDivisionTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FPegawaiTable ref="refPegawaiTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs>

  </v-card>
</template>

<script>
import FCompanyTable from "../../components/setting/FCompanyTable";
import FDivisionTable from "../../components/setting/FDivisionTable";
import FPegawaiTable from "../../components/setting/pegawai/FPegawaiTable";

export default {
  name: "StrukturOrganisasi",
  components: { FPegawaiTable, FCompanyTable, FDivisionTable},
  data() {
    return {
      firstTabTitle: 'STRUKTUR ORG',
      firstTabIcon: 'mdi-file-tree'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabCompanyClick(){
      //Company tidak mempunyai Parent Table
      // this.$refs.refCompanyTable.fetchParent()
    },
    tabDivisionClick(){
      try {
        this.$refs.refDivisionTable.fetchParent()
      }catch (e){
        e.toString()
      }

    },
    tabSalesmanClick(){
      try {
        this.$refs.refPegawaiTable.fetchParent()
      }catch (e) {
        e.toString()
      }
    },


  }
}
</script>

<style scoped>
</style>